import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { useCart } from './CartContext';
import LoginPopup from './LoginPopup';

const imageUrlLogoW = process.env.PUBLIC_URL + '/images/rmt-logo-white.png';
const imageUrlLogo = process.env.PUBLIC_URL + '/images/rmt-logo.png';

const LoggedInMenu = ({ userName, userEmail, handleLogout }) => {

    return (
        <>
            <List className="drawer-menu">
                <ListItemButton className="drawer-menu-link" component={Link} to="/account">
                    <span className="glyphicon glyphicon-stats"></span>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link" component={Link} to="/account">
                    <span className="glyphicon glyphicon-credit-card"></span>
                    <ListItemText primary="License Plate Information" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link" component={Link} to="/profile">
                    <span className="glyphicon glyphicon-user"></span>
                    <ListItemText primary="Your Profile" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link">
                    <span className="glyphicon glyphicon-search"></span>
                    <ListItemText primary="Property Lookup" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link" onClick={handleLogout}>
                    <span className="glyphicon glyphicon-off"></span>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </>
    );
};

const LoggedOutMenu = ({ handleLoginClick }) => {
    const { itemCount, totalDue } = useCart();

    return (
        <>
            <List className="drawer-menu">
                <ListItemButton className="drawer-menu-link" component={Link} to="/property/how/works">
                    <span className="glyphicon glyphicon-cog"></span>
                    <ListItemText primary="How it works" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link" component={Link} to="/property/pricing">
                    <span className="glyphicon glyphicon-usd"></span>
                    <ListItemText primary="Pricing" />
                </ListItemButton>

                <ListItemButton className="drawer-menu-link" component={Link} to="/account">
                    <span className="glyphicon glyphicon-user"></span>
                    <ListItemText primary="Sign In" />
                </ListItemButton>


                {/*<Link to="/" className="drawer-menu-link">
                    <span className="glyphicon glyphicon-search"></span> Property Lookup 
                </Link>*/}
            </List>
        </>
    );
};

const Header = () => {
    const [isLoginOpen, setLoginOpen] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [onSuccessRedirect, setOnSuccessRedirect] = useState("/account");
    const { itemCount, totalDue } = useCart();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                setUserName(user.name);
                setUserEmail(user.email);
            }
        }
    }, []);

    const handleLoginClick = (redirectPath) => {
        setOnSuccessRedirect(redirectPath || "/account");
        setLoginOpen(true);
    };

    const closeLoginPopup = () => {
        setLoginOpen(false);
    };

    const handleLogout = () => {
        logout();
        setLoggedIn(false);
        setUserName('');
        navigate('/');
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <>
            <AppBar position="fixed" color="primary" className="header-wrap">
                <Toolbar>
                    <IconButton className="menu-icon" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Link to="/" className="brand-name">
                        <img src={isLoggedIn ? imageUrlLogoW : imageUrlLogo} alt="RMT" style={{ height: 60 }} />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <Link to="/cart" className="cart-menu">
                        <span className="glyphicon glyphicon-shopping-cart"></span>
                        ${totalDue()}
                        <span className="badge">{itemCount()}</span>
                    </Link>
                </Toolbar>
            </AppBar>

            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} className="drawer-wrap">
                    <IconButton onClick={toggleDrawer(false)} className="close-drawer">
                        <CloseIcon />
                    </IconButton>
                    {isLoggedIn ? (
                        <LoggedInMenu userName={userName} userEmail={userEmail} handleLogout={handleLogout} />
                    ) : (
                        <LoggedOutMenu handleLoginClick={handleLoginClick} />
                    )}
                </Box>
            </Drawer>

            {isLoginOpen && (
                <LoginPopup isOpen={isLoginOpen} onRequestClose={closeLoginPopup} onSuccessRedirect={onSuccessRedirect} />
            )}
        </>
    );
};

export default Header;
